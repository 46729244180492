<template>
    <div>
        <ts-page-title
            :title="$t('workSchedule.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('workSchedule.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-loading-banner :loading="!shifts.length && ready_fetch">
                <ts-panel-wrapper>
                    <form class="form-horizontal form-horizontal-text-right">
                        <div class="tw-flex">
                            <div class="tw-flex">
                                <label
                                    class="form-label col-form-label col-md-3 tw-mr-1 required"
                                    >{{ $t('workSchedule.year') }}</label
                                >
                                <div>
                                    <DatePicker
                                        type="year"
                                        placeholder="Select year"
                                        v-model="model.cycle_year"
                                        format="yyyy"
                                        @on-change="onChangeYear"
                                    ></DatePicker>
                                </div>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_year')"
                                >
                                    {{ errors.first('cycle_year') }}
                                </div>
                            </div>
                            <div class="tw-flex">
                                <label
                                    class="form-label col-form-label col-md-3 tw-mr-1 required"
                                    >{{ $t('workSchedule.month') }}</label
                                >
                                <div>
                                    <DatePicker
                                        type="month"
                                        placeholder="Select month"
                                        v-model="model.cycle_month"
                                        format="MM"
                                        @on-change="onChangeMonth"
                                    ></DatePicker>
                                </div>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('cycle_month')"
                                >
                                    {{ errors.first('cycle_month') }}
                                </div>
                            </div>
                            <div class="tw-flex">
                                <label
                                    class="form-label col-form-label col-md-3 tw-mr-1 required"
                                    >{{ $t('workSchedule.shift') }}</label
                                >
                                <div>
                                    <select
                                        style="width: 150%"
                                        :class="{
                                            'is-invalid': errors.has('shift_id')
                                        }"
                                        v-model="model.shift_id"
                                        class="form-control"
                                        @change="fetchData"
                                    >
                                        <option
                                            value
                                            disabled
                                            selected
                                            style="display: none;"
                                            >{{ $t('select') }}</option
                                        >
                                        <option
                                            v-for="(shift, index) in shifts"
                                            :key="index"
                                            :value="shift.shift_id"
                                            >{{ shift.shift_name }} ({{
                                                shift.shift_type
                                            }})</option
                                        >
                                    </select>
                                </div>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('shift_id')"
                                >
                                    {{ errors.first('shift_id') }}
                                </div>
                            </div>
                            <div class="tw-flex-grow"></div>
                            <b-dropdown
                                :text="$t('export')"
                                class="tw-mr-2"
                                variant="default"
                                :waiting="loading"
                                :disabled="details.length <= 0 || loading"
                            >
                                <b-dropdown-item href="#" @click="exportExcel">
                                    <i
                                        class="fas fa-file-excel tw-text-green-500"
                                    ></i>
                                    {{ $t('excel') }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    href="#"
                                    disabled
                                    @click="exportPdf"
                                >
                                    <i
                                        class="fas fa-file-pdf tw-text-red-500"
                                    ></i>
                                    {{ $t('pdf') }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <ts-button
                                color="success"
                                outline
                                class="tw-mr-2"
                                @click.prevent="() => (showForm = true)"
                            >
                                {{ $t('copy') }}</ts-button
                            >
                            <ts-button
                                class="btn-primary"
                                :disabled="details.length <= 0"
                                @click.prevent="save"
                                :waiting="saving"
                            >
                                {{ $t('save') }}</ts-button
                            >
                        </div>
                    </form>
                    <ts-loading-banner :loading="loading">
                        <div class="menu-container">
                            <div class="schedule-table-container">
                                <table
                                    class="table-bordered"
                                    v-if="details.length > 0 && !loading"
                                >
                                    <thead>
                                        <tr>
                                            <th hidden>Staff ID</th>
                                            <th>
                                                {{
                                                    $t(
                                                        'workSchedule.employeeId'
                                                    )
                                                }}
                                            </th>
                                            <th>
                                                {{
                                                    $t(
                                                        'workSchedule.employeeName'
                                                    )
                                                }}
                                            </th>
                                            <th
                                                style="vertical-align: middle;"
                                                v-for="(column,
                                                index) in header"
                                                :key="index"
                                            >
                                                {{ subString(column) }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(data, index) in details"
                                            :key="index"
                                        >
                                            <td hidden>
                                                {{ data.employee_id }}
                                            </td>
                                            <td>{{ data.card_id }}</td>
                                            <td>{{ data.staff_name }}</td>
                                            <td
                                                v-for="(column,
                                                columnIndex) in header"
                                                :key="columnIndex"
                                            >
                                                <select
                                                    class="form-control border-none"
                                                    v-model="data[column]"
                                                >
                                                    <option
                                                        v-if="
                                                            data[column] ===
                                                                null
                                                        "
                                                        :value="null"
                                                        >-</option
                                                    >
                                                    <option v-else value
                                                        >-</option
                                                    >
                                                    <option
                                                        v-for="(special_keyword,
                                                        index) in special_keywords"
                                                        :key="index"
                                                        :value="
                                                            special_keyword.keyword
                                                        "
                                                        >{{
                                                            special_keyword.keyword
                                                        }}</option
                                                    >
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ts-loading-banner>
                </ts-panel-wrapper>
            </ts-loading-banner>
        </ts-panel>
        <!-- begin widget-list -->
        <div class="widget-list rounded tw-mt-4 tw-w-96">
            <!-- begin widget-list-item -->
            <a
                href="#"
                class="widget-list-item"
                v-for="(special_keyword, index) in special_keywords"
                :key="index"
            >
                <div class="widget-list-content">
                    <h4 class="widget-list-title">
                        {{ special_keyword.keyword }}
                    </h4>
                </div>
                <div
                    class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                >
                    {{ special_keyword.remarks }}
                </div>
            </a>
            <!-- end widget-list-item -->
        </div>
        <!-- end widget-list -->
        <!-- form-action -->
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :footer-hide="true"
            :z-index="1022"
            :title="$t('workSchedule.pageTitle')"
        >
            <copy-form @close="() => (showForm = false)" />
        </Modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import * as FileDownload from 'downloadjs'
import { Errors } from 'form-backend-validation'
import PreviewPdf from './preview-pdf'
import CopyForm from './copy-form'
import { trim } from 'lodash'

export default {
    name: 'work-schedule-form',
    components: {
        CopyForm
    },
    data () {
        return {
            model: {
                cycle_year: moment().format('YYYY'),
                cycle_month: moment().format('MM'),
                shift_id: ''
            },
            saving: false,
            header: [],
            details: [],
            loading: false,
            ready_fetch: false,
            exporting: false,
            showForm: false,
            errors: new Errors()
        }
    },
    computed: {
        ...mapState('humanResource/workSchedule', [
            'shifts',
            'special_keywords'
        ])
    },
    methods: {
        subString (string) {
            if (string === '' || string === undefined || string === null) return
            return string.substr(2)
        },

        fetchResource () {
            this.ready_fetch = true
            this.$store
                .dispatch('humanResource/workSchedule/getShift')
                .then(() => {
                    this.ready_fetch = false
                })
                .catch(error => {
                    this.ready_fetch = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        getSchedule () {
            this.header = []
            this.details = []
            this.loading = true
            this.$store
                .dispatch('humanResource/workSchedule/fetch', this.model)
                .then(response => {
                    this.header = response.header
                    this.details = response.data
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
        },
        async fetchData () {
            if (
                this.model.cycle_year == '' ||
                this.model.cycle_month == '' ||
                this.model.shift_id == ''
            )
                return
            this.loading = true
            await this.$store.dispatch(
                'humanResource/workSchedule/getSpecialKeyword',
                this.model.shift_id
            )
            await this.getSchedule()
        },
        async save () {
            this.errors = new Errors()
            this.saving = true

            try {
                let response = await this.$store.dispatch(
                    'humanResource/workSchedule/store',
                    Object.assign({}, this.model, { details: this.details })
                )

                this.notice({
                    type: response.message_id == 0 ? 'success' : 'warning',
                    text: response.message
                })
            } catch (error) {
                this.notice({ type: 'error', text: error.message })
                this.errors = new Errors(error.errors)
            }

            this.saving = false
        },
        exportExcel () {
            this.loading = true

            this.$store
                .dispatch('humanResource/workSchedule/exportExcel', this.model)
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => (this.loading = false))
        },
        exportPdf () {
            this.$modal.show(
                PreviewPdf,
                {
                    org_chart_structure_id: this.org_chart_structure_id,
                    cycle_year: this.cycle_year
                },
                {
                    height: 'auto',
                    width: '70%',
                    scrollable: true,
                    draggable: '.form-header',
                    clickToClose: false
                }
            )
        },
        onChangeYear (year) {
            this.model.cycle_year = year
            this.fetchData({
                cycle_year: this.model.cycle_year,
                cycle_month: this.model.cycle_month
            })
        },
        onChangeMonth (month) {
            this.model.cycle_month = month
            this.fetchData({
                cycle_year: this.model.cycle_year,
                cycle_month: this.model.cycle_month
            })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WORK SCHEDULE',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('humanResource/workSchedule/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    }
}
</script>
<style lang="scss" scoped>
.menu-container {
    margin-top: 16px;
    background-color: #fff;
    border: solid 1px #d3d8df;
    height: 56vh;
    overflow-y: scroll;
    overflow-x: scroll;
}
.schedule-table-container {
    height: 54vh;
    width: 100px;

    .table > thead > tr > th {
        position: sticky;
        top: 0;
        background: rgba(230, 230, 230, 1);
        z-index: 10;
    }
    table th,
    td {
        padding: 2px 4px;
        vertical-align: middle !important;
        white-space: nowrap;
    }
    .border-none {
        border: none;
        background: #fff;
        width: 45px;
        font-size: 11px;
        outline: none;
    }
}
::-webkit-scrollbar {
    height: 10px;
    width: 3px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #dee0e0;
}
div::-webkit-scrollbar-thumb:hover {
    background: #bebfc0;
}
/* This is to remove the arrow of select element in IE */
select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    text-align-last: center;
}

option {
    text-align: left;
}
</style>
